import React, {useState} from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { WebSocketProvider } from '../transport/WebSocketTransport';

import { AppLayout, AuthLayout } from './AppLayout';
//import {IndicatorsViewHz} from "../sections/indicators/IndicatorsViewHz"

import {LoginView} from "../sections/auth/LoginView";
import RegisterView from "../sections/auth/RegisterView";

import IndicatorsPage from "../sections/indicators/IndicatorsPage";
import IndicatorEditPage from "../sections/indicators/IndicatorEditPage";
import IndicatorTestPage from "../sections/indicators/IndicatorTestPage";
// import {PageLogin} from "../../sections/Auth/PageLogin";
// import {PageIndicator} from "../../sections/Indicators/PageIndicator";
// import {PageIndicatorTest} from "../../sections/Indicators/PageIndicatorTest";

export const AppRouter = () => {

    //localStorage.removeItem("skation_token")
    // Refresh main app using state
    const [refresh, setRefresh] = useState(0);
    const refreshApp = () => setRefresh(refresh + 1);
    const token = localStorage.getItem("skation_token")

    // Auth and register
    if (token === null) {
        return (
            <AuthLayout>

                <Routes>
                    <Route path="/" element={<Navigate to="/login" replace />} exact/>
                    <Route path={'/login'} element={<LoginView refreshApp={refreshApp}/>} />
                    <Route path={'/register'} element={<RegisterView refreshApp={refreshApp}/>} />
                    <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
            </AuthLayout>
        )
    }

    // Main layout
    return  (
        <WebSocketProvider refreshApp={refreshApp}>
        <AppLayout>

            <Routes>
                <Route path={'/indicators'} element={<IndicatorsPage refreshApp={refreshApp}/>} />
                {/* Маршрут для страницы создания нового индикатора */}
                {/* Маршрут для страницы редактирования индикатора по ID */}
                {/*<Route exact path="/indicators/edit/new" component={<IndicatorEditPage/>} />*/}
                <Route exact path="/indicators/edit/:id" element={<IndicatorEditPage/>} />
                <Route exact path="/indicators/test/:id" element={<IndicatorTestPage/>} />


                <Route path="/" element={<Navigate to="/indicators" replace />}  exact  />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>

        </AppLayout>
        </WebSocketProvider>
    )
}
