import React from 'react';
import { Layout, theme } from 'antd';
import { AppHeader } from './AppHeader';

const { Content } = Layout;

export const AppLayout = ({ children }) => {
    const { token } = theme.useToken();
    return (
        <Layout style={{ minHeight: '100dvh'}}>
                <Layout
                    style={{
                        maxWidth: 1440,
                        width: '100%',
                        margin: '0 auto',
                    }}
                >
                    <AppHeader />
                    <Content
                        style={{
                            margin: '0',
                            marginTop: '-10px',
                            padding: '0 24px',
                            backgroundColor: token.colorBgLayout,
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
        </Layout>
    );
};

export const AuthLayout = ({ children }) => {
    const { token } = theme.useToken();
    return (
        <Layout style={{ minHeight: '100dvh' }}>
            <Content
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: token.colorBgLayout,
                }}
            >
                {children}
            </Content>
        </Layout>
    );
};
