import { ConfigProvider, theme } from 'antd';
import {AppRouter} from "./app/AppRouter";
import {BrowserRouter} from "react-router-dom";
import './App.css';
import './i18n'

function App() {
  //localStorage.removeItem('syness_token');
  const appTheme = {
    token: {
        "colorPrimary": "#1ab5d4",
        "colorInfo": "#005f73",
        "colorSuccess": "#57cc99",
        "colorWarning": "#fcbf49",
        "colorError": "#d62828",
        "colorBgBase": "#0a0908",
        "fontSize": 15,
        "sizeStep": 4,
        "borderRadius": 6,
        "wireframe": true,
        "colorLink": "#33b9d4",

    },
    components: {
      Table: {colorBorder: 'red'},
      Notification: {
          colorText: "rgba(255,255,255,0.88)",
          background: '#0a0908',  // Dark background
          border: '1px solid #1ab5d4',  // Primary color border
          borderRadius: '6px',
      }

    },
    algorithm: theme.darkAlgorithm
  }

  return (
      <ConfigProvider theme={appTheme}>
        <BrowserRouter>
          <AppRouter/>
        </BrowserRouter>
      </ConfigProvider>
  )
}

export default App;
