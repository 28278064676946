// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Опционально: Детектор языка
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationES from './locales/es/translation.json';
import translationZH from './locales/zh/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    },
    es: {
        translation: translationES
    },
    zh: {
        translation: translationZH
    }
};

i18n
    .use(LanguageDetector) // Опционально: определение языка пользователя
    .use(initReactI18next) // Передаём i18n в react-i18next
    .init({
        resources,
        fallbackLng: 'en', // Язык по умолчанию, если выбранный язык недоступен
        debug: false, // Включите для отладки

        interpolation: {
            escapeValue: false // Реакт уже защищает от XSS
        }
    });

export default i18n;