import React, { useEffect, useRef, useCallback, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

// Компонент для чекбокса отображения объёма
const VolumeCheckbox = ({ showVolume, onChange }) => (
    <Checkbox
        checked={showVolume}
        onChange={onChange}
        style={{ marginBottom: '8px', alignSelf: 'flex-end' }}
    >
        Show Volume
    </Checkbox>
);

VolumeCheckbox.propTypes = {
    showVolume: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

// Компонент для контейнера графика
const ChartContainer = React.forwardRef(({ height }, ref) => (
    <div ref={ref} className="chart-container" style={{ height, width: '100%', position: 'relative' }}></div>
));

ChartContainer.propTypes = {
    height: PropTypes.number.isRequired,
};

ChartContainer.displayName = 'ChartContainer';

const Charts = React.memo(({ chartData, scheme }) => {
    const mainChartRef = useRef(null);
    const secondaryChartRef = useRef(null);
    const mainChartInstance = useRef(null);
    const secondaryChartInstance = useRef(null);
    const mainSeriesRef = useRef([]);
    const secondarySeriesRef = useRef([]);
    const [showVolume, setShowVolume] = useState(true);

    // Константы для типов точек
    const POINT_TOP_VALUE_SECONDARY = 70;
    const POINT_BOTTOM_VALUE_SECONDARY = -70;

    // Функция для конвертации времени из миллисекунд в целые секунды
    const convertTime = useCallback((timeInMillis) => {
        return Math.floor(timeInMillis / 1000);
    }, []);

    // Функция синхронизации кроссхайтеров
    const syncCharts = useCallback((sourceChart, targetChart) => {
        if (!sourceChart || typeof sourceChart.width !== 'function') {
            console.error('sourceChart не является валидным экземпляром Chart:', sourceChart);
            return;
        }
        if (!targetChart || typeof targetChart.moveCrosshair !== 'function') {
            console.error('targetChart не является валидным экземпляром Chart:', targetChart);
            return;
        }

        sourceChart.subscribeCrosshairMove(param => {
            if (
                !param ||
                !param.time ||
                param.point.x < 0 ||
                param.point.x > sourceChart.width() ||
                param.point.y < 0 ||
                param.point.y > sourceChart.height()
            ) {
                targetChart.moveCrosshair(null);
            } else {
                targetChart.moveCrosshair(param.time, param.point.y);
            }
        });
    }, []);

    // Функция синхронизации времени и прокрутки
    const syncScrollAndScale = useCallback((sourceChart, targetChart) => {
        if (!sourceChart || !targetChart) return;

        const sourceTimeScale = sourceChart.timeScale();
        const targetTimeScale = targetChart.timeScale();

        sourceTimeScale.subscribeVisibleTimeRangeChange(() => {
            const visibleLogicalRange = sourceTimeScale.getVisibleLogicalRange();
            if (visibleLogicalRange && targetTimeScale) {
                targetTimeScale.setVisibleLogicalRange(visibleLogicalRange);
            }
        });

        sourceTimeScale.subscribeVisibleLogicalRangeChange((logicalRange) => {
            if (logicalRange && targetTimeScale) {
                targetTimeScale.setVisibleLogicalRange(logicalRange);
            }
        });
    }, []);

    // Функция для приведения второго графика в синхронное время, даже если он пустой
    const ensureTimeRangeSync = useCallback((mainChart, secondaryChart) => {
        if (!mainChart || !secondaryChart) return;

        const mainTimeRange = mainChart.timeScale().getVisibleLogicalRange();
        const secondaryTimeRange = secondaryChart.timeScale().getVisibleLogicalRange();

        if (mainTimeRange && (!secondaryTimeRange || secondaryTimeRange.from !== mainTimeRange.from || secondaryTimeRange.to !== mainTimeRange.to)) {
            secondaryChart.timeScale().setVisibleLogicalRange(mainTimeRange);
        }
    }, []);

    // Инициализация графиков при монтировании компонента
    useEffect(() => {
        const commonChartSettings = {
            layout: {
                background: { type: 'solid', color: '#0a0908' },
                textColor: '#ffffff',
            },
            grid: {
                vertLines: { color: 'transparent' }, // Убираем вертикальные линии
                horzLines: { color: 'transparent' }, // Убираем горизонтальные линии
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                visible: false, // Отключаем отображение шкалы
                position: 'none', // Полностью отключаем шкалу
            },
            timeScale: {
                borderColor: '#0a0908', // Цвет фона для скрытия рамки
                timeVisible: true,
                secondsVisible: false, // Отключаем видимость секунд
                tickMarkFormatter: () => '', // Убираем метки времени
            },
            watermark: {
                visible: false,
            },
            handleScroll: true, // Разрешаем прокрутку
            handleScale: true,  // Разрешаем масштабирование
        };

        // Создание основного графика
        mainChartInstance.current = createChart(mainChartRef.current, {
            ...commonChartSettings,
            height: 420, // 70% от 600px
            width: mainChartRef.current.clientWidth,
        });

        // Создание вторичного графика с пустой временной шкалой
        secondaryChartInstance.current = createChart(secondaryChartRef.current, {
            ...commonChartSettings,
            height: 180, // 30% от 600px
            width: secondaryChartRef.current.clientWidth,
        });

        // Прокрутка обоих графиков до конца перед синхронизацией
        mainChartInstance.current.timeScale().scrollToRealTime();
        secondaryChartInstance.current.timeScale().scrollToRealTime();

        // Синхронизация графиков
        syncCharts(mainChartInstance.current, secondaryChartInstance.current);
        syncCharts(secondaryChartInstance.current, mainChartInstance.current);

        // Синхронизация прокрутки и масштаба
        syncScrollAndScale(mainChartInstance.current, secondaryChartInstance.current);
        syncScrollAndScale(secondaryChartInstance.current, mainChartInstance.current);

        // Принудительная синхронизация временного диапазона для второго графика
        ensureTimeRangeSync(mainChartInstance.current, secondaryChartInstance.current);

        // Обработчик изменения размера окна
        const handleResize = () => {
            if (mainChartInstance.current && mainChartRef.current) {
                mainChartInstance.current.applyOptions({ width: mainChartRef.current.clientWidth });
            }
            if (secondaryChartInstance.current && secondaryChartRef.current) {
                secondaryChartInstance.current.applyOptions({ width: secondaryChartRef.current.clientWidth });
            }
        };

        window.addEventListener('resize', handleResize);

        // Очистка при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
            if (mainChartInstance.current) {
                mainChartInstance.current.remove();
            }
            if (secondaryChartInstance.current) {
                secondaryChartInstance.current.remove();
            }
        };
    }, [syncCharts, syncScrollAndScale, ensureTimeRangeSync]);

    // Функция обновления графиков при изменении данных или схемы
    const updateCharts = useCallback(() => {
        if (!chartData || chartData.length === 0) return;

        const mainChart = mainChartInstance.current;
        const secondaryChart = secondaryChartInstance.current;

        if (!mainChart || !secondaryChart) return;

        // Удаление старых серий
        mainSeriesRef.current.forEach(series => mainChart.removeSeries(series));
        mainSeriesRef.current = [];
        secondarySeriesRef.current.forEach(series => secondaryChart.removeSeries(series));
        secondarySeriesRef.current = [];

        // Сортировка и удаление дубликатов по времени
        const uniqueChartData = [...chartData]
            .sort((a, b) => a.time - b.time)
            .filter((item, index, arr) => index === 0 || item.time !== arr[index - 1].time);

        // Добавление свечного графика
        const candlestickSeries = mainChart.addCandlestickSeries({
            upColor: 'green',
            downColor: 'red',
            borderVisible: false,
            wickUpColor: 'green',
            wickDownColor: 'red',
            priceLineVisible: false,
        });
        mainSeriesRef.current.push(candlestickSeries);

        const candlestickData = uniqueChartData.map(item => ({
            time: convertTime(item.time), // Время в секундах
            open: item.open,
            high: item.high,
            low: item.low,
            close: item.close,
        }));
        candlestickSeries.setData(candlestickData);

        // Добавление объёма, если включен флаг showVolume
        if (showVolume) {
            const volumeSeries = mainChart.addHistogramSeries({
                color: 'rgba(0, 150, 136, 0.8)',
                priceFormat: { type: 'volume' },
                priceScaleId: 'volume',
                scaleMargins: { top: 0.8, bottom: 0 },
                priceLineVisible: false,
            });
            mainSeriesRef.current.push(volumeSeries);

            const volumeData = uniqueChartData.map(item => ({
                time: convertTime(item.time),
                value: typeof item.volume === 'number' ? item.volume : 0, // Устанавливаем 0, если volume некорректен
                color: item.close > item.open ? 'rgba(0, 150, 136, 0.8)' : 'rgba(255, 82, 82, 0.8)',
            }));
            volumeSeries.setData(volumeData);
        }

        // Добавление скрытой последовательности данных на второй график для синхронизации времени
        if (uniqueChartData.length > 0) {
            const hiddenSeries = secondaryChart.addLineSeries({
                color: 'transparent', // Прозрачная линия
                lineWidth: 1,
                visible: false, // Серия скрыта
                priceLineVisible: false,
            });

            const hiddenData = uniqueChartData.map(item => ({
                time: convertTime(item.time),
                value: 0, // Значение 0, но линия невидима
            }));

            hiddenSeries.setData(hiddenData);
            secondarySeriesRef.current.push(hiddenSeries);
        }

        // Рендеринг индикаторов
        scheme.forEach(indicator => {
            const targetChart = indicator.chart === 0 ? mainChart : secondaryChart;
            let series;

            // Обработка данных индикатора
            const indicatorData = uniqueChartData
                .map(item => {
                    const rawValue = item[indicator.name];
                    let value = null;
                    let text = '';

                    switch (indicator.type) {
                        case 0: // Line
                        case 1: // Area
                            if (typeof rawValue === 'number') {
                                value = rawValue;
                                text = indicator.name;
                                return { time: convertTime(item.time), value, text };
                            }
                            return null;
                        case 2: // Point
                        case 3: // Point Top
                        case 4: // Point Bottom
                            if (indicator.chart === 0) { // Main chart
                                if (typeof rawValue === 'boolean') {
                                    if (rawValue) {
                                        value = item.close;
                                        text = indicator.name;
                                        return { time: convertTime(item.time), value, text };
                                    }
                                    return null; // Не отображаем точку, если false
                                }
                                if (typeof rawValue === 'number') {
                                    value = rawValue;
                                    text = indicator.name;
                                    return { time: convertTime(item.time), value, text };
                                }
                                return null;
                            } else if (indicator.chart === 1) { // Secondary chart
                                if (typeof rawValue === 'boolean') {
                                    if (rawValue) {
                                        value = indicator.type === 3 ? POINT_TOP_VALUE_SECONDARY : POINT_BOTTOM_VALUE_SECONDARY;
                                        text = indicator.name;
                                        return { time: convertTime(item.time), value, text };
                                    }
                                    return null; // Не отображаем точку, если false
                                }
                                if (typeof rawValue === 'number') {
                                    value = rawValue;
                                    text = indicator.name;
                                    return { time: convertTime(item.time), value, text };
                                }
                                return null;
                            }
                        default:
                            console.warn(`Неизвестный тип индикатора: ${indicator.type}`);
                            return null;
                    }
                })
                .filter(data => data !== null && typeof data.value === 'number'); // Фильтрация некорректных данных

            if (indicatorData.length === 0) {
                // Нет данных для отображения
                return;
            }

            console.log(`Indicator Data for ${indicator.name}:`, indicatorData); // Логирование для отладки

            switch (indicator.type) {
                case 0: // Line
                    series = targetChart.addLineSeries({
                        color: indicator.color,
                        lineWidth: 2,
                        priceLineVisible: false,
                    });
                    break;
                case 1: // Area
                    series = targetChart.addAreaSeries({
                        topColor: indicator.color,
                        bottomColor: 'rgba(0, 0, 0, 0)',
                        lineColor: indicator.color,
                        lineWidth: 2,
                        priceLineVisible: false,
                    });
                    break;
                case 2: // Point
                case 3: // Point Top
                case 4: // Point Bottom
                    series = targetChart.addLineSeries({
                        color: indicator.color,
                        lineWidth: 0,
                        priceLineVisible: false,
                    });
                    series.setMarkers(indicatorData.map(item => ({
                        time: item.time,
                        position: 'aboveBar',
                        color: indicator.color,
                        shape: 'circle',
                        text: item.text, // Отображаем имя индикатора
                    })));
                    break;
                default:
                    // Уже обработано выше
                    break;
            }

            if (series) {
                series.setData(indicatorData.map(item => ({
                    time: item.time,
                    value: item.value,
                })));
                if ([0, 2, 3, 4].includes(indicator.type)) { // Line, Point, Point Top, Point Bottom
                    if (indicator.chart === 0) {
                        mainSeriesRef.current.push(series);
                    } else {
                        secondarySeriesRef.current.push(series);
                    }
                } else if (indicator.type === 1) { // Area
                    if (indicator.chart === 0) {
                        mainSeriesRef.current.push(series);
                    } else {
                        secondarySeriesRef.current.push(series);
                    }
                }
            }
        });

        // Прокрутка графиков к последнему времени (выравнивание по правому краю)
        mainChart.timeScale().scrollToRealTime();
        secondaryChart.timeScale().scrollToRealTime();

        // Принудительная синхронизация временного диапазона для второго графика
        ensureTimeRangeSync(mainChartInstance.current, secondaryChartInstance.current);
    }, [chartData, scheme, showVolume, convertTime, ensureTimeRangeSync]);

    // Обновление графиков при изменении данных или схемы
    useEffect(() => {
        updateCharts();
    }, [updateCharts]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <VolumeCheckbox
                showVolume={showVolume}
                onChange={(e) => setShowVolume(e.target.checked)}
            />
            <div style={{ display: 'flex', flexDirection: 'column', height: '600px', gap: '0px' }}>
                <ChartContainer ref={mainChartRef} height={420} /> {/* 70% от 600px */}
                <ChartContainer ref={secondaryChartRef} height={180} /> {/* 30% от 600px */}
            </div>
        </div>
    );
});

Charts.propTypes = {
    chartData: PropTypes.arrayOf(PropTypes.shape({
        time: PropTypes.number.isRequired, // Время в миллисекундах
        open: PropTypes.number.isRequired,
        high: PropTypes.number.isRequired,
        low: PropTypes.number.isRequired,
        close: PropTypes.number.isRequired,
        volume: PropTypes.number,
    })).isRequired,
    scheme: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        chart: PropTypes.number.isRequired, // 0 для основного, 1 для вторичного
        type: PropTypes.number.isRequired,  // 0: Line, 1: Area, 2: Point, 3: Point Top, 4: Point Bottom
        color: PropTypes.string.isRequired,
    })).isRequired,
};

export default Charts;
