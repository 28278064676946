import React, {useState} from 'react';
import {Form, Input, Button, Checkbox, message, Typography, Tag, Alert} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import Transport from "../../transport/Transport";

export function LoginView({refreshApp}) {

    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleResponse = (response) => {
        setIsSubmitting(false);
        if (response.token !== undefined) {
            // Auth ok. got token
            localStorage.setItem("skation_token", response.token)
            message.success('Login success.');
            refreshApp()
        } else if (response.error !== undefined) {
            // Auth error. got error text in error variable
            message.error('Login error.');
            setErrorMessage(response.error)
        } else {
            // Server error?
            message.error('Server error.');
            setErrorMessage("Unknown error.")
        }
    };

    function onFinish(values) {
        console.log('Received values from form: ', values);

        setErrorMessage('');
        setIsSubmitting(true);
        const method = 'login';
        const object = 'auth';
        setIsSubmitting(true);
        Transport(object, method, values, handleResponse, refreshApp)

    };

    function registerClicked(event) {
        navigate("/register");
    }




    return (
        <>


        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >


            <div
                style={{
                    position: 'relative',
                    width: 400,
                    padding: '20px 30px 20px 30px',
                    backgroundColor: '#151310', // Темный фон формы
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.7)',
                    color: '#fff', // Белый текст на темном фоне
                }}
            >


                {/* Контейнер для картинки */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <img
                        src="/logo.png"
                        alt="logo"
                        style={{
                            marginLeft: -25,
                            height: 80,
                        }}
                    />
                </div>

                {errorMessage && (
                    <Alert
                        message={errorMessage}
                        style={{marginTop: 15}}
                        type="error"
                        showIcon
                    />
                )}
                <Form
                    name="login_form"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    style={{marginTop: 20, maxWidth: "100%"}}
                >
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: 'Please input your Username.'},
                            { min: 6, message: 'Username must be at least 6 characters long.' }]}
                    >
                        <Input
                            prefix={<UserOutlined/>}
                            placeholder="Username"
                            size="large"
                            style={{
                                backgroundColor: '#333',
                                color: '#fff',
                                border: '1px solid #444', // Темный бордер инпутов
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Please input your Password.'},
                            { min: 8, message: 'Password must be at least 8 characters long.' } ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined/>}
                            placeholder="Password"
                            size="large"
                            style={{
                                backgroundColor: '#333',
                                color: '#fff',
                                border: '1px solid #444',
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            style={{
                                width: '100%',
                            }}
                            disabled={isSubmitting}
                        >
                            Log in
                        </Button>
                    </Form.Item>
                    <div>
                        To register click <a href="#" onClick={registerClicked}>here</a>.
                    </div>
                </Form>
            </div>
        </div>
        </>
    );
};

export default LoginView;
