import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    notification,
    Breadcrumb,
    Typography,
    Spin,
    Row,
    Col,
    DatePicker,
} from 'antd';
import { ArrowLeftOutlined, EyeOutlined } from '@ant-design/icons'; // Импорт иконки
import { useNavigate, useParams } from 'react-router-dom';
import { useWebSocket } from '../../transport/WebSocketTransport';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs'; // Импорт dayjs
import Charts from './components/Charts'; // Импортируем компонент для графиков

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

// Стиль для рамки
const columnStyle = {
    border: '1px solid #333',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#0a0908',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

const IndicatorTestPage = () => {
    const { id } = useParams(); // Получение ID индикатора из URL
    const { sendMessage, subscribeToObjects, connected } = useWebSocket();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    // Состояния для индикатора и тестирования
    const [loading, setLoading] = useState(true);
    const [indicatorData, setIndicatorData] = useState(null);
    const [parsedParams, setParsedParams] = useState([]);
    const [parsedScheme, setParsedScheme] = useState([]);
    const [testResult, setTestResult] = useState(null);

    // Состояния для маркета, тикера и временных рамок
    const [markets, setMarkets] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [tickers, setTickers] = useState([]);
    const [selectedTicker, setSelectedTicker] = useState(null);
    const [chartData, setChartData] = useState(null); // Данные для графика
    const [chartScheme, setChartScheme] = useState(null); // Схема индикаторов

    // Состояния для диапазона дат
    const [rangeFrom, setRangeFrom] = useState(dayjs().subtract(3, 'days').unix());
    const [rangeTo, setRangeTo] = useState(dayjs().unix());

    // Отдельное состояние, чтобы не устанавливать первый маркет снова при каждом обновлении
    const [initialized, setInitialized] = useState(false);

    // Новое состояние для отслеживания, был ли уже выполнен рендер
    const [hasRendered, setHasRendered] = useState(false);

    // Новое состояние для отслеживания загрузки графика
    const [chartLoading, setChartLoading] = useState(false);

    useEffect(() => {
        if (!connected) return;

        // Отправка запроса на получение данных индикатора
        sendMessage({ object: 'indicators', method: 'get', data: { id } });

        // Отправка запроса на получение списка маркетов
        sendMessage({ object: 'markets', method: 'list', data: {} });

        const unsubscribe = subscribeToObjects(['indicators', 'markets'], (data) => {
            console.log('Received data:', data); // Логирование для отладки

            // Обработка ответа на получение индикатора
            if (
                (data.object === 'indicators') &&
                data.method === 'get' &&
                data.data.record &&
                data.data.record.id.toString() === id
            ) {
                try {
                    const record = data.data.record;
                    const parsedParams = record.params ? JSON.parse(record.params) : [];
                    const parsedScheme = record.scheme ? JSON.parse(record.scheme) : [];
                    setParsedParams(parsedParams);
                    setParsedScheme(parsedScheme);
                    setIndicatorData(record);

                    // Установка значений по умолчанию в форму (без поля range)
                    const defaultValues = {};
                    parsedParams.forEach(param => {
                        defaultValues[param.name] = param.default || '';
                    });
                    // Добавляем начальные значения для timeframe
                    defaultValues.timeframe = 1;
                    form.setFieldsValue(defaultValues);

                    setLoading(false);
                } catch (error) {
                    console.error('Error parsing params or scheme:', error);
                    notification.error({
                        message: t('Error'),
                        description: t('Failed to parse indicator data'),
                        type: 'error',
                    });
                    setLoading(false);
                }
            }

            // Обработка ответа на получение маркетов
            else if (data.object === 'markets' && data.method === 'list') {
                const marketsList = data.data.list || [];
                setMarkets(marketsList);

                // Установить первый маркет по умолчанию только при первом рендере
                if (!initialized && marketsList.length > 0) {
                    const firstMarket = marketsList[0];
                    setSelectedMarket(firstMarket.id);
                    form.setFieldsValue({ market_id: firstMarket.id });
                    // Отправка запроса на получение тикеров для первого маркета
                    sendMessage({ object: 'markets', method: 'tickers', data: { id: firstMarket.id } });
                    setInitialized(true); // Указываем, что первый маркет установлен
                }
            }

            // Обработка ответа на получение тикеров
            else if (data.object === 'markets' && data.method === 'tickers') {
                const marketId = data.data.id;
                if (marketId === selectedMarket) {
                    const tickersList = data.data.list || [];
                    setTickers(tickersList);
                    if (tickersList.length > 0) {
                        const firstTicker = tickersList[0];
                        setSelectedTicker(firstTicker); // Запоминаем первый тикер
                        form.setFieldsValue({ ticker_id: firstTicker });
                    } else {
                        setSelectedTicker(null);
                        form.setFieldsValue({ ticker_id: null });
                    }
                }
            }

            // Обработка ответа на запрос для рендеринга графика
            else if (data.object === 'indicators' && data.method === 'chart') {
                setChartData(data.data.chart); // Устанавливаем данные для графика
                setChartScheme(parsedScheme); // Устанавливаем схему индикаторов
                setChartLoading(false); // Завершаем загрузку графика
            }
        });

        return () => {
            unsubscribe();
        };
    }, [id, sendMessage, subscribeToObjects, connected, form, t, selectedMarket, initialized]);

    // useEffect для автоматического рендеринга после установки первого тикера
    useEffect(() => {
        if (selectedTicker && !hasRendered) {
            handleRenderClick();
            setHasRendered(true);
        }
    }, [selectedTicker, hasRendered]);

    // Обработчик изменения маркета
    const handleMarketChange = (value) => {
        setSelectedMarket(value);
        setSelectedTicker(null);
        form.setFieldsValue({ ticker_id: null });
        // Отправка запроса на получение тикеров для выбранного маркета
        sendMessage({ object: 'markets', method: 'tickers', data: { id: value } });
    };

    // Обработчик изменения тикера
    const handleTickerChange = (value) => {
        setSelectedTicker(value);
        form.setFieldsValue({ ticker_id: value });
    };

    // Обработчик изменения таймфрейма
    const handleTimeframeChange = (e) => {
        const value = e.target.value;
        form.setFieldsValue({ timeframe: value });
    };

    // Обработчик изменения диапазона времени
    const handleRangeChange = (dates) => {
        if (dates && dates.length === 2) {
            setRangeFrom(dates[0].unix());
            setRangeTo(dates[1].unix());
        } else {
            // Если диапазон очищен, можно установить значения по умолчанию или оставить пустыми
            setRangeFrom(null);
            setRangeTo(null);
        }
    };

    // Обработчик нажатия кнопки Render
    const handleRenderClick = () => {
        form
            .validateFields()
            .then(values => {
                const { timeframe, market_id, ticker_id, ...params } = values;

                // Проверка наличия диапазона дат
                if (!rangeFrom || !rangeTo) {
                    notification.error({
                        message: t('Error'),
                        description: t('Please select a valid date range'),
                        type: 'error',
                    });
                    return;
                }

                const settings = {
                    market_id,
                    ticker: ticker_id,
                    timeframe,
                    from: rangeFrom,
                    to: rangeTo,
                };

                // Начало загрузки графика
                setChartLoading(true);

                sendMessage({
                    object: 'indicators',
                    method: 'chart',
                    data: {
                        id,
                        params,
                        settings,
                    },
                });
            })
            .catch(errorInfo => {
                console.error('Validation Failed:', errorInfo);
            });
    };

    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div style={{ backgroundColor: '#0a0908', padding: '24px', borderRadius: '12px' }}>
            {/* Breadcrumb */}
            <Breadcrumb style={{ marginBottom: '24px' }}>
                <Breadcrumb.Item>
                    <span
                        onClick={() => navigate('/indicators')}
                        style={{ cursor: 'pointer', textDecoration: 'underline', color: '#00ffff' }}
                    >
                        {t('Indicators')}
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item style={{ color: '#fff' }}>
                    {t('Test Indicator')} - {indicatorData.name}
                </Breadcrumb.Item>
            </Breadcrumb>

            {/* Параметры и график в рамке */}
            <div style={columnStyle}>
                {/* Рендеринг графика или спинера */}
                <div style={{ marginTop: '24px', flex: 1 }}>
                    {chartLoading ? (
                        <div style={{ textAlign: 'left', paddingBottom: '30px' }}>
                            <Spin size="large" />
                        </div>
                    ) : chartData && chartScheme ? (
                        <Charts chartData={chartData} scheme={chartScheme} />
                    ) : null}
                </div>

                <Form form={form} layout="vertical" size="small">
                    {/* Параметры (params) */}
                    <Row gutter={24}>
                        {parsedParams.map((param) => (
                            <Col xs={24} sm={6} md={4} lg={3} key={param.name}>
                                <Form.Item
                                    name={param.name}
                                    label={param.name}
                                    rules={[
                                        { required: param.required || false, message: `${t('Please input')} ${param.name}` },
                                    ]}
                                >
                                    {param.type === 'number' ? (
                                        <Input type="number" placeholder={param.description || ''} />
                                    ) : param.type === 'textarea' ? (
                                        <Input.TextArea placeholder={param.description || ''} />
                                    ) : (
                                        <Input placeholder={param.description || ''} />
                                    )}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>

                    {/* Поля для выбора маркета, тикера, таймфрейма, диапазона времени и кнопки */}
                    <Row gutter={24} style={{ marginTop: '8px' }}>
                        <Col xs={24} sm={6} md={4}>
                            <Form.Item
                                name="market_id"
                                label={t('Market')}
                                rules={[{ required: true, message: t('Please select a market') }]}
                            >
                                <Select
                                    placeholder={t('Select Market')}
                                    onChange={handleMarketChange}
                                    value={selectedMarket}
                                    allowClear
                                >
                                    {markets.map((market) => (
                                        <Option key={market.id} value={market.id}>
                                            {market.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={6} md={4}>
                            <Form.Item
                                name="ticker_id"
                                label={t('Ticker')}
                                rules={[{ required: true, message: t('Please select a ticker') }]}
                            >
                                <Select
                                    placeholder={t('Select Ticker')}
                                    onChange={handleTickerChange}
                                    value={selectedTicker}
                                    disabled={!tickers.length}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    allowClear
                                >
                                    {tickers.map((ticker) => (
                                        <Option key={ticker} value={ticker}>
                                            {ticker}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={4} md={2}>
                            <Form.Item
                                name="timeframe"
                                label={t('Timeframe')}
                                rules={[{ required: true, message: t('Please input a timeframe') }]}
                            >
                                <Input
                                    type="number"
                                    min={1}
                                    // Значение управляется через Form, поэтому value и onChange не нужны здесь
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={10} md={10}>
                            <Form.Item
                                label={t('Date Range')}
                            >
                                <RangePicker
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                    value={[
                                        rangeFrom ? dayjs.unix(rangeFrom) : null,
                                        rangeTo ? dayjs.unix(rangeTo) : null,
                                    ]}
                                    onChange={handleRangeChange}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={4} md={4}>
                            <Button
                                type="primary"
                                onClick={handleRenderClick}
                                style={{ marginTop: '30px' }}
                                icon={<EyeOutlined />}
                            >
                                {t('Render')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            {/* Кнопка Back за рамкой */}
            <Button
                type="default"
                onClick={() => navigate('/indicators')}
                icon={<ArrowLeftOutlined />}
                style={{ marginTop: '24px' }}
                size={"small"}
            >
                {t('Back')}
            </Button>
        </div>
    );
};

export default IndicatorTestPage;
