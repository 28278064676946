import React, {createContext, useEffect, useRef, useContext, useState, useCallback} from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ refreshApp, children }) => {
    const ws = useRef(null);
    const subscribers = useRef({});

    const [session, setSession] = useState(null);
    const [connected, setConnected] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('skation_token'));

    useEffect(() => {
        const handleTokenChange = () => {
            const currentToken = localStorage.getItem('skation_token');
            console.log('Токен изменён:', currentToken);
            setToken(currentToken);
        };

        const handleStorage = (event) => {
            if (event.key === 'skation_token') {
                handleTokenChange();
            }
        };
        window.addEventListener('storage', handleStorage);
        return () => {
            window.removeEventListener('storage', handleStorage);
        };
    }, []);

    useEffect(() => {
        if (!token) {
            console.warn('Нет skation_token. WebSocket не будет подключаться.');
            if (ws.current) {
                ws.current.close();
                ws.current = null;
            }
            setConnected(false);
            setSession(null);
            return;
        }

        //const wsUrl = `wss://${window.location.host}/ws?token=${token}`;
        const wsUrl = `/ws?token=${token}`;
        console.log("Подключение к WebSocket по URL:", wsUrl);

        ws.current = new ReconnectingWebSocket(wsUrl, [], {
            reconnectInterval: 5000, // Интервал переподключения
            maxRetries: 10,           // Максимальное количество попыток
            debug: true,              // Включить отладочные сообщения
        });

        ws.current.addEventListener('open', () => {
            console.log('WebSocket подключен');
            setConnected(true);
        });

        ws.current.addEventListener('close', (event) => {
            console.log('WebSocket закрыт:', event.reason);
            setConnected(false);
        });

        ws.current.addEventListener('error', (error) => {
            console.error('WebSocket ошибка:', error);
            setConnected(false);
        });

        ws.current.addEventListener('message', (event) => {
            try {
                const data = JSON.parse(event.data);
                const { object } = data;
                console.log('Получено сообщение по WebSocket:', data);

                if (data['auth_needed'] !== undefined) {
                    alert('Требуется аутентификация.');
                    localStorage.removeItem('skation_token');

                    refreshApp().catch(err => console.error('Ошибка при обновлении приложения:', err));
                    return;
                }

                if (object === 'session' && data['data'] !== undefined) {
                    setSession(data['data']);
                }

                if (subscribers.current[object]) {
                    subscribers.current[object].forEach((callback) => {
                        try {
                            callback(data);
                        } catch (err) {
                            console.error('Ошибка в подписчике:', err);
                        }
                    });
                }
            } catch (err) {
                console.error('Ошибка при обработке сообщения WebSocket:', err);
            }
        });

        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    }, [token, refreshApp]);

    const sendMessage = useCallback(
        (message) => {
            if (ws.current && connected) {
                console.log('Отправка сообщения:', message);
                ws.current.send(JSON.stringify(message));
            } else {
                console.warn('Невозможно отправить сообщение, WebSocket не подключен');
            }
        },
        [connected]
    );

    const subscribeToObjects = useCallback((objectTypes, callback) => {
        const objectsArray = Array.isArray(objectTypes) ? objectTypes : [objectTypes];

        objectsArray.forEach((objectType) => {
            if (!subscribers.current[objectType]) {
                subscribers.current[objectType] = [];
            }
            subscribers.current[objectType].push(callback);
        });

        return () => {
            objectsArray.forEach((objectType) => {
                subscribers.current[objectType] = subscribers.current[objectType].filter(
                    (cb) => cb !== callback
                );
                if (subscribers.current[objectType].length === 0) {
                    delete subscribers.current[objectType];
                }
            });
        };
    }, []);

    return (
        <WebSocketContext.Provider value={{ ws, sendMessage, subscribeToObjects, session, connected }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
