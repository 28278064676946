import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';

const { Option } = Select;

const languageOptions = [
    { value: 'en', label: 'English', countryCode: 'US' },
    { value: 'es', label: 'Español', countryCode: 'ES' },
    { value: 'ru', label: 'Русский', countryCode: 'RU' },
    { value: 'zh', label: '中文', countryCode: 'CN' },
];

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const handleChange = (value) => {
        i18n.changeLanguage(value);
    };

    return (
        <Select
            value={i18n.language}
            onChange={handleChange}
            style={{ width: 150 }}
            dropdownStyle={{ width: 150 }}
            optionLabelProp="label"
            size={"small"}
            style={{paddingRight: '10px'}}
        >
            {languageOptions.map(lang => (
                <Option key={lang.value} value={lang.value} label={lang.label}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ReactCountryFlag
                            countryCode={lang.countryCode}
                            svg
                            style={{
                                width: '1.5em',
                                height: '1.5em',
                                marginRight: '0.5em',
                            }}
                            title={lang.countryCode}
                        />
                        <span>{lang.label}</span>
                    </div>
                </Option>
            ))}
        </Select>
    );
};

export default LanguageSwitcher;
