// ParamsEditor.js
import React, { useState, memo, useEffect } from 'react';
import { Button, Input, Space, Typography, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

// Мемоизированный компонент ParamItem для оптимизации производительности
const ParamItem = memo(({ param, index, updateParam, removeParam }) => {
    const { name, default: defaultVal, min, max } = param;
    const { t } = useTranslation();

    // Обработчик подтверждения удаления
    const confirmDelete = () => {
        removeParam(index);
    };

    return (
        <Space
            key={index}
            style={{
                marginBottom: 8,
                display: 'flex',
                alignItems: 'center',
            }}
            size="middle"
        >
            <Input
                placeholder={t('name')}
                value={name}
                onChange={(e) => updateParam(index, 'name', e.target.value)}
                size="small"
            />
            <Input
                placeholder={t('default')}
                type="number"
                value={defaultVal}
                onChange={(e) => updateParam(index, 'default', e.target.value)}
                size="small"
            />
            <Input
                placeholder={t('min')}
                type="number"
                value={min}
                onChange={(e) => updateParam(index, 'min', e.target.value)}
                size="small"
            />
            <Input
                placeholder={t('max')}
                type="number"
                value={max}
                onChange={(e) => updateParam(index, 'max', e.target.value)}
                size="small"
            />
            {/* Tooltip и Popconfirm для кнопки удаления */}
            <Popconfirm
                title={t('Are you sure you want to delete this param?')}
                onConfirm={confirmDelete}
                okText={t('confirm')}
                cancelText={t('cancel')}
                placement="top"
            >
                <Tooltip title={t('Delete')}>
                    <Button
                        danger
                        size="small"
                        icon={<DeleteOutlined />}
                        aria-label={t('delete')}
                    />
                </Tooltip>
            </Popconfirm>
        </Space>
    );
});

export const ParamsEditor = ({ value = [], onChange }) => {
    const { t } = useTranslation();
    const [params, setParams] = useState(value);

    // Синхронизация внутреннего состояния с пропсами
    useEffect(() => {
        setParams(value);
    }, [value]);

    const addParam = () => {
        const newParam = { name: '', default: 0, min: 0, max: 0 };
        const updatedParams = [...params, newParam];
        setParams(updatedParams);
        onChange && onChange(updatedParams);
    };

    const updateParam = (index, key, val) => {
        const updatedParams = params.map((param, i) =>
            i === index ? { ...param, [key]: key === 'name' ? val : Number(val) } : param
        );
        setParams(updatedParams);
        onChange && onChange(updatedParams);
    };

    const removeParam = (index) => {
        const updatedParams = params.filter((_, i) => i !== index);
        setParams(updatedParams);
        onChange && onChange(updatedParams);
    };

    return (
        <div>
            {/* Заголовок с метками */}
            <Space
                style={{
                    marginBottom: 8,
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                }}
                size="middle"
            >
                <Text>{t('name')}</Text>
                <Text>{t('default')}</Text>
                <Text>{t('min')}</Text>
                <Text>{t('max')}</Text>
                <Text>{t('actions')}</Text>
            </Space>

            {/* Отображение параметров */}
            {params.map((param, index) => (
                <ParamItem
                    key={index}
                    param={param}
                    index={index}
                    updateParam={updateParam}
                    removeParam={removeParam}
                />
            ))}

            {/* Кнопка добавления параметра */}
            <div style={{ marginTop: 16 }}>
                <Button
                    type="dashed"
                    onClick={addParam}
                    icon={<PlusOutlined />}
                    size="small"
                    block
                >
                    {t('addParam')}
                </Button>
            </div>
        </div>
    );
};
