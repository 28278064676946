// EntityGroupManager.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Table,
    Button,
    Modal,
    Input,
    Space,
    Tooltip,
    Pagination,
    Popconfirm,
    Form,
    notification,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    ReloadOutlined,
    SaveOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useWebSocket } from '../transport/WebSocketTransport';

const EntityGroupManager = ({ entity, visible, onClose, setParentGroups }) => {
    const { sendMessage, subscribeToObjects } = useWebSocket();
    const { t } = useTranslation();
    const [groupForm] = Form.useForm();
    const [editingGroupId, setEditingGroupId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [editedName, setEditedName] = useState('');
    const [filterName, setFilterName] = useState('');
    const [groups, setGroups] = useState([]);

    const editInputRef = useRef(null);

    const iconMap = {
        success: <CheckCircleOutlined style={{ color: '#57cc99' }} />,
        info: <InfoCircleOutlined style={{ color: '#33b9d4' }} />,
        warning: <ExclamationCircleOutlined style={{ color: '#fcbf49' }} />,
        error: <CloseCircleOutlined style={{ color: '#d62828' }} />,
    };

    // Open notification with inline styles
    const openNotification = (message, description, type = 'success') => {
        notification[type]({
            message,
            description,
            icon: iconMap[type],
        });
    };

    const fetchGroups = useCallback(() => {
        sendMessage({ object: 'groups', method: 'list', data: { entity } });
    }, [sendMessage, entity]);

    useEffect(() => {
        if (visible) {
            fetchGroups();

            const unsubscribe = subscribeToObjects(['groups', 'random'], (data) => {
                if (data['object'] === 'groups' && data['method'] === 'list') {
                    setGroups(data['data'].list);
                    // Update parent component's groups with 'No Group' added
                    if (setParentGroups) {
                        setParentGroups(data['data'].list);
                    }
                } else if (data['object'] === 'random' && data['method'] === 'get') {
                    groupForm.setFieldsValue({ name: data['data'].value });
                }
            });

            return () => {
                unsubscribe();
            };
        }
    }, [visible, fetchGroups, subscribeToObjects]); // Removed 'groupForm' and 'setParentGroups' from dependencies

    const handleGroupSave = useCallback(
        (groupId) => {
            const updatedName = editedName;
            if (updatedName) {
                sendMessage({
                    object: 'groups',
                    method: 'update',
                    data: { id: groupId, name: updatedName, entity },
                });
                openNotification(t('Success'), t('Group name updated!'), 'success');
                setEditingGroupId(null);
                fetchGroups();
            }
        },
        [editedName, entity, sendMessage, t, fetchGroups]
    );

    const startEditing = useCallback((groupId, groupName) => {
        setEditingGroupId(groupId);
        setEditedName(groupName);
        setTimeout(() => {
            if (editInputRef.current) {
                editInputRef.current.focus();
            }
        }, 0);
    }, []);

    const handleInputChange = (e) => {
        setEditedName(e.target.value);
    };

    const handleKeyPress = (e, groupId) => {
        if (e.key === 'Enter') {
            handleGroupSave(groupId);
        }
    };

    const confirmDelete = (groupId) => {
        sendMessage({ object: 'groups', method: 'delete', data: { id: groupId } });
        openNotification(t('Success'), t('Group deleted!'), 'success');
        fetchGroups();
    };

    const getRandomGroupName = () => {
        sendMessage({ object: 'random', method: 'get', data: { entity: 'groups' } });
    };

    const handleCreateGroup = useCallback(
        (values) => {
            const newGroup = { ...values, entity, id: 0 };
            sendMessage({ object: 'groups', method: 'update', data: newGroup });

            openNotification(t('Success'), t('Group successfully created!'), 'success');
            groupForm.resetFields();
            fetchGroups();
        },
        [sendMessage, entity, groupForm, t, fetchGroups]
    );

    const filteredGroups = groups.filter((group) =>
        group.name.toLowerCase().includes(filterName.toLowerCase())
    );

    const paginatedGroups = filteredGroups.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const groupColumns = [
        {
            title: (
                <div>
                    <Input
                        placeholder={t('Filter by name')}
                        size="small"
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}
                        style={{ marginTop: 0, width: '100%' }}
                    />
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (_, group) => {
                return editingGroupId === group.id ? (
                    <Input
                        ref={editInputRef}
                        value={editedName}
                        onChange={handleInputChange}
                        size="small"
                        style={{ flex: 1, marginRight: '8px' }}
                        onKeyPress={(e) => handleKeyPress(e, group.id)}
                    />
                ) : (
                    <div style={{ padding: '4px 8px', flex: 1 }}>{group.name}</div>
                );
            },
        },
        {
            title: t(''),
            width: '1%',
            key: 'actions',
            align: 'right',
            render: (_, group) => (
                <Space size={4}>
                    {editingGroupId === group.id ? (
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            size="small"
                            onClick={() => handleGroupSave(group.id)}
                        />
                    ) : (
                        <Tooltip title={t('Edit')}>
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                size="small"
                                onClick={() => startEditing(group.id, group.name)}
                            />
                        </Tooltip>
                    )}
                    <Popconfirm
                        title={t('Are you sure you want to delete this group?')}
                        onConfirm={() => confirmDelete(group.id)}
                        okText={t('Yes')}
                        cancelText={t('No')}
                        icon={<InfoCircleOutlined style={{ color: 'red' }} />}
                    >
                        <Tooltip title={t('Delete')}>
                            <Button danger icon={<DeleteOutlined />} size="small" />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Modal
            title={t('Manage Groups')}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" size={"small"} onClick={onClose}>
                    {t('Close')}
                </Button>,
            ]}
        >
            {groups.length > 0 && (
                <>
                    <Table
                        dataSource={paginatedGroups}
                        columns={groupColumns}
                        rowKey="id"
                        pagination={false}
                        bordered
                        style={{ marginBottom: '16px' }}
                        size="small"
                    />

                    {filteredGroups.length > pageSize && (
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={filteredGroups.length}
                            size={"small"}
                            onChange={(page) => setCurrentPage(page)}
                            style={{ marginBottom: '16px', textAlign: 'center' }}
                        />
                    )}
                </>
            )}

            <Form form={groupForm} layout="vertical" onFinish={handleCreateGroup}>
                <Space style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <Form.Item
                        name="name"
                        label={t('Group Name')}
                        style={{ flex: 1 }}
                        rules={[{ required: true, message: t('Please enter a group name!') }]}
                    >
                        <Input
                            placeholder={t('Enter group name')}
                            size="small"
                            suffix={
                                <Tooltip title={t('Get random name')}>
                                    <Button
                                        icon={<ReloadOutlined />}
                                        onClick={getRandomGroupName}
                                        style={{ border: 'none', padding: 0 }}
                                        size="small"
                                    />
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="small"
                        style={{ flexShrink: 0, marginTop: 5 }}
                    >
                        {t('Create')}
                    </Button>
                </Space>
            </Form>
        </Modal>
    );
};

export default EntityGroupManager;
