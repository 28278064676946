// IndicatorEditPage.js
import React, { useState, useEffect, useCallback } from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    Space,
    Row,
    Col,
    notification,
    Breadcrumb,
    Tooltip,
    Typography,
    Modal,
} from 'antd';
import {
    ReloadOutlined,
    RobotOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    SaveOutlined,
    LayoutOutlined,
    EditOutlined,
    ExperimentOutlined, ArrowLeftOutlined, EyeOutlined, // Imported the test icon
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useWebSocket } from '../../transport/WebSocketTransport';
import { useTranslation } from 'react-i18next';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import { ParamsEditor } from './components/ParamsEditor';
import { SchemeEditor } from './components/SchemeEditor';
import EntityGroupManager from '../../shared/EntityGroupManager';

const { Title } = Typography;

const columnStyle = {
    border: '1px solid #333',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#0a0908',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

const IndicatorEditPage = () => {
    const { id } = useParams();
    const { sendMessage, subscribeToObjects, connected } = useWebSocket();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const [scheme, setScheme] = useState([]);
    const [code, setCode] = useState('');
    const [aiModalVisible, setAiModalVisible] = useState(false);
    const [aiLoading, setAiLoading] = useState(false);
    const [aiInput, setAiInput] = useState('');
    const [isSchemeModalVisible, setSchemeModalVisible] = useState(false);
    const [isCodeModalVisible, setCodeModalVisible] = useState(false);
    const [modalCode, setModalCode] = useState('');
    const [isGroupManagerVisible, setGroupManagerVisible] = useState(false);
    const [submitType, setSubmitType] = useState('save'); // Added submitType state

    // Loading groups
    useEffect(() => {
        if (!connected) return;

        sendMessage({ object: 'groups', method: 'list', data: { entity: 'indicators' } });

        const unsubscribe = subscribeToObjects(['groups'], (data) => {
            if (data.object === 'groups' && data.method === 'list') {
                // Always add 'No Group' option when updating groups
                setGroups([{ id: 0, name: 'No Group' }, ...data.data.list]);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [sendMessage, subscribeToObjects, connected]);

    // Loading indicator data
    useEffect(() => {
        if (!connected) return;

        if (id !== '0') {
            sendMessage({ object: 'indicators', method: 'get', data: { id } });

            const unsubscribe = subscribeToObjects(['indicators'], (data) => {
                if (data.object === 'indicators' && data.method === 'get') {
                    let record = { ...data.data.record };

                    // Parse 'params' if it's a string
                    if (typeof record.params === 'string') {
                        try {
                            record.params = JSON.parse(record.params);
                        } catch (e) {
                            console.error('Error parsing params:', e);
                            record.params = [];
                        }
                    }

                    // Parse 'scheme' if it's a string
                    if (typeof record.scheme === 'string') {
                        try {
                            record.scheme = JSON.parse(record.scheme);
                        } catch (e) {
                            console.error('Error parsing scheme:', e);
                            record.scheme = [];
                        }
                    }

                    // Set form fields
                    form.setFieldsValue({
                        ...record,
                        group_id: record.group_id !== undefined ? record.group_id : 0,
                        code: record.code,
                        params: record.params,
                        scheme: record.scheme,
                    });

                    setScheme(record.scheme);
                    setCode(record.code);
                }
            });

            return () => {
                unsubscribe();
            };
        } else {
            // Set default values for a new indicator
            form.setFieldsValue({
                name: '',
                group_id: 0,
                description: '',
                code: "# Your code\n# df - dataframe [time,open,high,low,close]\ndef indicator(df, params):\n    return df\n",
                params: [],
                scheme: [],
            });
            setScheme([]);
            setCode(
                "# Your code\n# df - dataframe [time,open,high,low,close]\ndef indicator(df, params):\n    return df\n"
            );
        }
    }, [id, sendMessage, subscribeToObjects, form, connected]);

    // Synchronize scheme state with Form's 'scheme' field
    useEffect(() => {
        form.setFieldsValue({ scheme });
    }, [scheme, form]);

    // Form submission handler
    const onFinish = (values) => {
        const payload = {
            ...values,
            id: id === '0' ? undefined : id,
            // Convert 'params' and 'scheme' to JSON strings if server expects text
            params: JSON.stringify(values.params),
            scheme: JSON.stringify(scheme),
        };

        // Send the update message
        sendMessage({ object: 'indicators', method: 'update', data: payload });

        // Subscribe to the 'indicators' 'update' response
        const unsubscribe = subscribeToObjects(['indicators'], (data) => {
            if (data.object === 'indicators' && data.method === 'update') {
                notification.success({
                    message: t('Success'),
                    description: t('Indicator saved successfully'),
                    type: 'success',
                });

                if (submitType === 'saveAndTest') {
                    const newId = data.data.id;
                    navigate(`/indicators/test/${newId}`);
                } else {
                    navigate('/indicators');
                }

                // Reset the submitType to default
                setSubmitType('save');

                // Unsubscribe after handling
                unsubscribe();
            }
        });
    };

    // AI code generation handler
    const handleAiGenerate = () => {
        if (!connected) return;

        setAiLoading(true);
        sendMessage({ object: 'ai', method: 'generate_code', data: { description: aiInput } });

        const unsubscribe = subscribeToObjects(['ai'], (data) => {
            setAiLoading(false);
            if (data.data?.code) {
                setCode(data.data.code);
                form.setFieldsValue({ code: data.data.code }); // Update form's 'code' field

                // Check if 'params' are returned
                if (data.data.params) {
                    let aiParams = data.data.params;
                    // Ensure aiParams is an array
                    if (!Array.isArray(aiParams)) {
                        aiParams = [];
                    }
                    form.setFieldsValue({ params: aiParams }); // Update form's 'params' field
                }

                setAiModalVisible(false);
                notification.success({
                    message: t('Success'),
                    description: t('Code and parameters generated successfully'),
                    type: 'success',
                });
            } else if (data.data?.error) {
                notification.error({
                    message: t('Error'),
                    description: data.data.error,
                    type: 'error',
                });
            }

            // Unsubscribe after handling
            unsubscribe();
        });
    };

    // Random name generator handler
    const getRandomName = useCallback(() => {
        if (!connected) return;

        sendMessage({ object: 'random', method: 'get', data: { entity: 'indicators' } });

        const unsubscribe = subscribeToObjects(['random'], (data) => {
            if (data.object === 'random' && data.method === 'get') {
                form.setFieldsValue({ name: data.data.value });
            }
            // Unsubscribe after handling
            unsubscribe();
        });
    }, [connected, sendMessage, subscribeToObjects, form]);

    // Scheme Modal handlers
    const openSchemeModal = () => setSchemeModalVisible(true);
    const closeSchemeModal = () => setSchemeModalVisible(false);

    // Full-Screen Code Editor Modal Handlers
    const openCodeModal = () => {
        setModalCode(code); // Initialize modal code with current code
        setCodeModalVisible(true);
    };

    const closeCodeModal = () => {
        setCodeModalVisible(false);
    };

    const openGroupManager = () => setGroupManagerVisible(true);
    const closeGroupManager = () => setGroupManagerVisible(false);

    const handleCodeModalSave = () => {
        setCode(modalCode);
        form.setFieldsValue({ code: modalCode });
        setCodeModalVisible(false);
        notification.success({
            message: t('Success'),
            description: t('Code updated successfully'),
            type: 'success',
        });
    };

    const handleCodeModalCancel = () => {
        setModalCode(code); // Reset modal code to current code
        setCodeModalVisible(false);
    };

    return (
        <div style={{ backgroundColor: '#0a0908', padding: '24px', borderRadius: '12px' }}>
            {/* Breadcrumb */}
            <Breadcrumb style={{ marginBottom: '24px' }}>
                <Breadcrumb.Item>
                    <span
                        onClick={() => navigate('/indicators')}
                        style={{ cursor: 'pointer', textDecoration: 'underline', color: '#00ffff' }}
                    >
                        {t('Indicators')}
                    </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item style={{ color: '#fff' }}>
                    {id === '0' ? t('New Indicator') : t('Edit Indicator')}
                </Breadcrumb.Item>
            </Breadcrumb>

            <Form form={form} layout="vertical" onFinish={onFinish}>
                {/* Main Content: Two Columns */}
                <Row gutter={24} align="stretch">
                    {/* Left Column: Params Editor and Code Editor */}
                    <Col xs={24} sm={24} md={12}>
                        <div style={columnStyle}>
                            {/* Params Editor */}
                            <Row gutter={24} style={{ marginBottom: '16px', flex: '1' }}>
                                <Col span={24}>
                                    <Form.Item label={t('Params')} name="params">
                                        <ParamsEditor
                                            onChange={(updatedParams) =>
                                                form.setFieldsValue({ params: updatedParams })
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Code Editor */}
                            <Row gutter={24} style={{ flex: '1' }}>
                                <Col span={24}>
                                    <Form.Item
                                        name="code"
                                        label={t('Code')}
                                        rules={[{ required: true, message: t('Please enter the code') }]}
                                    >
                                        {/* Wrapper div for AceEditor to apply border and border-radius */}
                                        <div
                                            style={{
                                                border: '1px solid #544b43',
                                                borderRadius: '4px',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <AceEditor
                                                mode="python"
                                                theme="monokai"
                                                name="codeEditor"
                                                fontSize={14}
                                                width="100%"
                                                height="200px"
                                                setOptions={{ useWorker: false }}
                                                value={code}
                                                onChange={(value) => {
                                                    setCode(value);
                                                    form.setFieldsValue({ code: value });
                                                }}
                                            />
                                        </div>
                                        <Space style={{ marginTop: '12px' }}>
                                            {/* Generate with AI Button with Icon */}
                                            <Button
                                                type="default"
                                                onClick={() => setAiModalVisible(true)}
                                                size="small"
                                                icon={<RobotOutlined />}
                                            >
                                                {t('Generate with AI')}
                                            </Button>
                                            {/* Fullscreen Edit Button with Icon */}
                                            <Button
                                                type="default"
                                                onClick={openCodeModal}
                                                size="small"
                                                icon={
                                                    isCodeModalVisible ? (
                                                        <FullscreenExitOutlined />
                                                    ) : (
                                                        <FullscreenOutlined />
                                                    )
                                                }
                                            >
                                                {isCodeModalVisible ? t('Exit Fullscreen') : t('Fullscreen Edit')}
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    {/* Right Column: Name, Group, Scheme Button, Description */}
                    <Col xs={24} sm={24} md={12}>
                        <div style={columnStyle}>
                            {/* Name Field */}
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="name"
                                        label={<span>{t('Name')} </span>}
                                        rules={[{ required: true, message: t('Please enter the name') }]}
                                    >
                                        <Input
                                            size="small"
                                            suffix={
                                                <Tooltip title={t('Get Random Name')}>
                                                    <Button
                                                        icon={<ReloadOutlined />}
                                                        onClick={getRandomName}
                                                        style={{ border: 'none', padding: 0 }}
                                                        size="small"
                                                    />
                                                </Tooltip>
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Group Field */}
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label={t('Group')} required={false}>
                                        <Row gutter={8}>
                                            <Col flex="auto">
                                                <Form.Item
                                                    name="group_id"
                                                    noStyle
                                                    rules={[{ required: false, message: t('Please select a group') }]}
                                                >
                                                    <Select
                                                        placeholder={t('Select Group')}
                                                        size="small"
                                                    >
                                                        {groups.map((group) => (
                                                            <Select.Option key={group.id} value={group.id}>
                                                                {group.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col flex="none">
                                                <Tooltip title={t('Manage Groups')}>
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        onClick={openGroupManager}
                                                        size="small"
                                                    />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <EntityGroupManager
                                        entity="indicators"
                                        visible={isGroupManagerVisible}
                                        onClose={closeGroupManager}
                                        setParentGroups={(updatedGroups) => {
                                            // Always add 'No Group' when updating groups
                                            setGroups([{ id: 0, name: t('No group') }, ...updatedGroups]);
                                        }}
                                    />
                                </Col>
                            </Row>

                            {/* Scheme Button as a Form Item */}
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={
                                            <span>
                                                <span style={{ color: '#b12322' }}>*</span> {t('Scheme')}{' '}
                                            </span>
                                        }
                                        name="scheme"
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (scheme.length > 0) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(t('Scheme is required')));
                                                },
                                            },
                                        ]}
                                    >
                                        <Button
                                            type={scheme.length > 0 ? 'primary' : 'default'}
                                            // style={
                                            //     scheme.length > 0
                                            //         ? {
                                            //             backgroundColor: '#52c41a', // Ant Design's success color
                                            //             borderColor: '#52c41a',
                                            //         }
                                            //         : {}
                                            // }
                                            onClick={openSchemeModal}
                                            size="small"
                                            icon={<LayoutOutlined />}
                                        >
                                            {scheme.length > 0 ? t('Scheme OK') : t('Edit Scheme')}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Description Field */}
                            <Row gutter={24} style={{ flex: '1' }}>
                                <Col span={24}>
                                    <Form.Item
                                        name="description"
                                        label={t('Description')}
                                        style={{ height: '100%' }}
                                    >
                                        <Input.TextArea
                                            size="small"
                                            style={{ height: '100%' }}
                                            autoSize={{ minRows: 3, maxRows: 6 }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {/* Form Footer with Adjusted Position */}
                <Form.Item style={{ marginTop: '24px' }}>
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="small"
                            disabled={scheme.length === 0}
                            icon={<SaveOutlined />}
                            onClick={() => setSubmitType('save')} // Set submitType to 'save'
                        >
                            {t('Save')}
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="small"
                            disabled={scheme.length === 0}
                            icon={<ExperimentOutlined />} // Test icon
                            onClick={() => setSubmitType('saveAndTest')} // Set submitType to 'saveAndTest'
                        >
                            {t('Save and Test')}
                        </Button>
                        <Button onClick={() => navigate('/indicators')} size="small" icon={<ArrowLeftOutlined />}>
                            {t('Back')}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>

            {/* Modal for AI Code Generation */}
            <Modal
                title={t('Generate Code with AI')}
                visible={aiModalVisible}
                onCancel={() => setAiModalVisible(false)}
                footer={[
                    <Button size={"small"} key="back" onClick={() => setAiModalVisible(false)}>
                        {t('Cancel')}
                    </Button>,
                    <Button  size={"small"}  key="submit" type="primary" loading={aiLoading} onClick={handleAiGenerate}>
                        {t('Generate')}
                    </Button>,
                ]}
            >
                <Input.TextArea
                    rows={4}
                    value={aiInput}
                    onChange={(e) => setAiInput(e.target.value)}
                    placeholder={t('Describe the logic or strategy for the indicator')}
                />
            </Modal>

            {/* Modal for Scheme Editor */}
            <Modal
                title={t('Edit Scheme')}
                visible={isSchemeModalVisible}
                onCancel={closeSchemeModal}
                footer={[
                    <Button key="cancel" onClick={closeSchemeModal} size="small">
                        {t('Cancel')}
                    </Button>,
                    <Button key="save" type="primary" onClick={closeSchemeModal} size="small">
                        {t('Save')}
                    </Button>,
                ]}
                width="100%" // Make modal full width
                style={{ top: 0, padding: 0 }} // Remove default padding
                bodyStyle={{ backgroundColor: '#0a0908', padding: 0 }} // Set background color and height
                destroyOnClose
            >
                <SchemeEditor
                    scheme={scheme}
                    setScheme={setScheme}
                    code={code}
                    params={form.getFieldValue('params') || []}
                />
            </Modal>

            {/* Modal for Full-Screen Code Editor */}
            <Modal
                title={t('Full-Screen Code Editor')}
                visible={isCodeModalVisible}
                onCancel={handleCodeModalCancel}
                footer={[
                    <Button key="cancel" onClick={handleCodeModalCancel} size="small">
                        {t('Cancel')}
                    </Button>,
                    <Button key="save" type="primary" onClick={handleCodeModalSave} size="small">
                        {t('Save')}
                    </Button>,
                ]}
                width="100%"
                style={{ top: 0, padding: 0 }}
                bodyStyle={{ height: '90vh', padding: 0 }}
                destroyOnClose
            >
                {/* Wrapper div to ensure AceEditor fills the modal and has no additional padding */}
                <div
                    style={{
                        height: '100%',
                        border: '1px solid #544b43',
                        borderRadius: '4px',
                        overflow: 'hidden',
                    }}
                >
                    <AceEditor
                        mode="python"
                        theme="monokai"
                        name="fullscreenCodeEditor"
                        fontSize={14}
                        width="100%"
                        height="100%"
                        setOptions={{ useWorker: false }}
                        value={modalCode}
                        onChange={(value) => setModalCode(value)}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default IndicatorEditPage;
