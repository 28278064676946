// IndicatorsPage.js
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    Button,
    Table,
    Spin,
    Space,
    Tooltip,
    Select,
    notification,
    Popconfirm,
    Breadcrumb,
} from 'antd';
import { useTranslation } from 'react-i18next';
import EntityGroupManager from '../../shared/EntityGroupManager';
import { useWebSocket } from '../../transport/WebSocketTransport';
import { EditOutlined, PlusOutlined, DeleteOutlined, ExperimentOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

// Updated Top Controls Component
const TopControls = ({
                         groups,
                         selectedGroupId,
                         setSelectedGroupId,
                         onManageGroups,
                         onCreateIndicator,
                         noGroupText,
                         starGroupText,
                         t,
                         setPagination,
                     }) => (
    <Space
        style={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}
        wrap
    >
        <Space>
            <Select
                placeholder={t('Filter by Group')}
                onChange={(value) => {
                    setSelectedGroupId(value !== undefined ? value : 0); // Set to 0 by default when cleared
                    setPagination((prev) => ({ ...prev, current: 1 }));
                }}
                style={{ width: 200 }}
                allowClear
                value={selectedGroupId}
                size="small"
            >
                {/* Display group with id:0 as "*" in the dropdown */}
                <Select.Option key={0} value={0}>
                    {starGroupText}
                </Select.Option>
                {groups.map((group) => (
                    <Select.Option key={group.id} value={group.id}>
                        {group.name}
                    </Select.Option>
                ))}
            </Select>

            <Tooltip title={t('Manage Groups')}>
                <Button icon={<EditOutlined />} onClick={onManageGroups} size="small" />
            </Tooltip>
        </Space>

        <Tooltip title={t('New Indicator')}>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onCreateIndicator}
                size="small"
            >
                {t('New Indicator')}
            </Button>
        </Tooltip>
    </Space>
);

const IndicatorsPage = () => {
    const { t } = useTranslation();
    const [isGroupManagerVisible, setGroupManagerVisible] = useState(false);
    const [indicators, setIndicators] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { sendMessage, subscribeToObjects, connected } = useWebSocket();
    const navigate = useNavigate();

    // Set the selected group to 0 by default ("*")
    const [selectedGroupId, setSelectedGroupId] = useState(0);

    // Memoize translations to prevent unnecessary re-renders
    const noGroupText = useMemo(() => t('No group'), [t]);
    const starGroupText = useMemo(() => t('*'), [t]);

    // Initialize pagination state
    const [pagination, setPagination] = useState({
        current: 1, // Ant Design Table uses 1-based indexing
        pageSize: 10,
        total: 0,
        showSizeChanger: false,
    });

    // Fetch groups when connected
    useEffect(() => {
        if (!connected) return;

        sendMessage({ object: 'groups', method: 'list', data: { entity: 'indicators' } });

        const unsubscribe = subscribeToObjects(['groups'], (data) => {
            if (data.object === 'groups' && data.method === 'list') {
                // Ensure group with id:0 is added only once
                const fetchedGroups = data.data.list.filter(group => group.id !== 0);
                setGroups(fetchedGroups);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [connected, sendMessage, subscribeToObjects]);

    // Fetch indicators when dependencies change
    useEffect(() => {
        if (!connected) return;

        let filters = {};

        if (selectedGroupId !== null && selectedGroupId !== 0) {
            filters['group_id'] = selectedGroupId;
        }

        setIsLoading(true);

        // Prepare pagination parameters for the server request
        const paginationParams = {
            page: pagination.current - 1, // Convert to 0-based indexing for the server
            count: pagination.pageSize,
            sort: 'id', // Adjust as needed
            desc: true, // Adjust as needed
            no_pagination: false,
        };

        sendMessage({
            object: 'indicators',
            method: 'list',
            data: {
                filters,
                pagination: paginationParams,
            },
        });

        const unsubscribe = subscribeToObjects(['indicators'], (data) => {
            if (data.object === 'indicators' && data.method === 'list') {
                setIndicators(data.data.list);

                // Update pagination state based on server response
                const serverPagination = data.data.pagination || {};
                setPagination((prev) => ({
                    ...prev,
                    total: serverPagination.total || data.data.list.length,
                    // Do not update the current page from the server response to prevent jumping to the first page
                    pageSize: serverPagination.count || prev.pageSize,
                }));

                setIsLoading(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [
        connected,
        sendMessage,
        subscribeToObjects,
        selectedGroupId,
        pagination.current,
        pagination.pageSize,
    ]);

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    const openGroupManager = () => setGroupManagerVisible(true);
    const closeGroupManager = () => setGroupManagerVisible(false);

    const handleDelete = useCallback(
        (id) => {
            sendMessage({ object: 'indicators', method: 'delete', data: { id } });

            notification.success({
                message: t('Success'),
                description: t('Indicator deleted successfully'),
            });

            // Re-fetch indicators after deletion
            setPagination((prev) => ({ ...prev, current: 1 }));
        },
        [sendMessage, t]
    );

    const indicatorColumns = useMemo(
        () => [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 80,
            },
            {
                title: t('Name'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: t('Description'),
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: t('Group'),
                dataIndex: 'group_id',
                key: 'group_id',
                render: (groupId) => {
                    // Display "No group" if groupId = 0 or group not found
                    const group = groups.find((g) => g.id === groupId);
                    return groupId === 0 || !group ? noGroupText : group.name;
                },
            },
            {
                title: t('Actions'),
                key: 'actions',
                align: 'right',
                render: (_, record) => (

                    <Space size="4">
                        <Tooltip title={t('Test')}>
                            <Button
                                icon={<ExperimentOutlined />}
                                onClick={() => navigate(`/indicators/test/${record.id}`)}
                                size="small"
                                style={{ marginRight: 4 }}
                            />
                        </Tooltip>
                        <Tooltip title={t('Edit')}>
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                onClick={() => navigate(`/indicators/edit/${record.id}`)}
                                size="small"
                                style={{ marginRight: 4 }}
                            />
                        </Tooltip>
                        <Popconfirm
                            title={t('Are you sure you want to delete this indicator?')}
                            onConfirm={() => handleDelete(record.id)}
                            okText={t('Yes')}
                            cancelText={t('No')}
                        >
                            <Tooltip title={t('Delete')}>
                                <Button icon={<DeleteOutlined />} size="small" danger />
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                ),
            },
        ],
        [groups, navigate, t, handleDelete, noGroupText]
    );

    return (
        <div style={{ padding: '24px', backgroundColor: '#0a0908', minHeight: '100vh' }}>

            <Breadcrumb style={{ marginBottom: '24px', color: '#ffffff' }}>
                <Breadcrumb.Item>{t('Indicators')}</Breadcrumb.Item>
            </Breadcrumb>

            {/* Top Controls: Filter by Group, Manage Groups Button, and New Indicator Button */}
            <TopControls
                groups={groups}
                selectedGroupId={selectedGroupId}
                setSelectedGroupId={setSelectedGroupId}
                onManageGroups={openGroupManager}
                onCreateIndicator={() => navigate(`/indicators/edit/0`)}
                noGroupText={noGroupText}
                starGroupText={starGroupText}
                t={t}
                setPagination={setPagination}
            />

            {isLoading ? (
                <div style={{ padding: '0px 0', textAlign: 'center' }}>
                    <Spin size="large" tip={t('Loading...')} />
                </div>
            ) : (
                <Table
                    dataSource={indicators}
                    columns={indicatorColumns}
                    rowKey="id"
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: pagination.total,
                        size: 'small',
                        hideOnSinglePage: true,
                        position: ['bottomLeft'], // Align pagination to the left
                    }}
                    size="small"
                    onChange={handleTableChange}
                    style={{ backgroundColor: '#0a0908', color: '#ffffff' }}
                    // Optional: Add row class name for better contrast
                    rowClassName={() => 'indicator-row'}
                />
            )}

            {/* Removed BottomButton as it's no longer needed */}

            <EntityGroupManager
                entity="indicators"
                visible={isGroupManagerVisible}
                onClose={closeGroupManager}
                setParentGroups={(updatedGroups) => {
                    // Ensure group with id:0 is not added again
                    const filteredGroups = updatedGroups.filter(group => group.id !== 0);
                    setGroups(filteredGroups);
                }}
            />
        </div>
    );
};

export default IndicatorsPage;
