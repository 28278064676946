// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global override for Ant Design notification in dark theme */
.ant-notification-notice {
  background-color: #0a0908 !important; /* Dark background */
  color: #ffffff !important;            /* White text */
}

.ant-notification-notice-message {
  color: #ffffff !important;            /* White text for the title */
}

.ant-notification-notice-description {
  color: #ffffff !important;            /* White text for the description */
}

.ant-notification-notice-icon {
  color: #1ab5d4 !important;            /* Custom color for the icon */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,8DAA8D;AAC9D;EACE,oCAAoC,EAAE,oBAAoB;EAC1D,yBAAyB,aAAa,eAAe;AACvD;;AAEA;EACE,yBAAyB,aAAa,6BAA6B;AACrE;;AAEA;EACE,yBAAyB,aAAa,mCAAmC;AAC3E;;AAEA;EACE,yBAAyB,aAAa,8BAA8B;AACtE","sourcesContent":["/* Global override for Ant Design notification in dark theme */\n.ant-notification-notice {\n  background-color: #0a0908 !important; /* Dark background */\n  color: #ffffff !important;            /* White text */\n}\n\n.ant-notification-notice-message {\n  color: #ffffff !important;            /* White text for the title */\n}\n\n.ant-notification-notice-description {\n  color: #ffffff !important;            /* White text for the description */\n}\n\n.ant-notification-notice-icon {\n  color: #1ab5d4 !important;            /* Custom color for the icon */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
