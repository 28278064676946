import React from 'react';
import { Layout, Menu, Space } from 'antd';
import WebSocketStatusTag from "../shared/WebSocketStatusTag";
import LanguageSwitcher from "../shared/LanguageSwitcher";

const { Header } = Layout;

const items = [
    {
        label: 'Indicators',
        key: '/indicators',
    },
    // {
    //     label: 'Engines',
    //     key: '/engines',
    // }
];

export function AppHeader() {
    return (
        <Header style={{
            backgroundColor: '#0a0908',
            padding: '0 20px',
            display: 'flex',
            alignItems: 'center',
            height: '80px',
            boxSizing: 'border-box', // Убедитесь, что padding учитывается в общей высоте
        }}>
            <img
                src="/logo.png"
                alt="logo"
                style={{
                    height: 40,
                    marginRight: 20,
                }}
            />
            <Menu
                mode="horizontal"
                defaultSelectedKeys={['/indicators']}
                items={items}
                style={{
                    backgroundColor: '#0a0908',
                    flex: 1,
                    borderBottom: 'none',
                    lineHeight: '40px', // Центрирование меню по вертикали
                }}
            />
            <Space size="middle" style={{ display: 'flex', alignItems: 'center' }}>
                <LanguageSwitcher />
                <WebSocketStatusTag />
            </Space>
        </Header>
    );
}
