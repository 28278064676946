import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import { useWebSocket } from '../transport/WebSocketTransport'; // Adjust the import path accordingly
import { LinkOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const WebSocketStatusTag = () => {
    const { connected } = useWebSocket();
    const [status, setStatus] = useState('Connecting');

    useEffect(() => {
        if (connected === true) {
            setStatus('Connected');
        } else if (connected === false) {
            setStatus('Disconnected');
        }
    }, [connected]);

    let color = '';

    switch (status) {
        case 'Connected':
            color = 'cyan'; // Green color suitable for dark theme
            break;
        case 'Disconnected':
            color = 'volcano'; // Red color suitable for dark theme
            break;
        case 'Connecting':
            color = 'gold'; // Blue color suitable for dark theme
            break;
        default:
            color = 'red'; // Yellow color for any other status
    }

    return <Tag color={color}><LinkOutlined/> {status}</Tag>;
};

export default WebSocketStatusTag;
