// SchemeEditor.js
import React, { useState, useEffect, memo } from 'react';
import { Button, Input, Space, Popover, Spin, Select, notification } from 'antd';
import { SketchPicker } from 'react-color';
import { DeleteOutlined } from '@ant-design/icons';
import { useWebSocket } from '../../../transport/WebSocketTransport';
import { useTranslation } from 'react-i18next';
import Charts from './Charts';

const SchemeItem = memo(
    ({ schemeItem, index, updateScheme, removeScheme, colorPickerVisible, setColorPickerVisible }) => {
        const { t } = useTranslation();

        return (
            <Space key={index} style={{ marginBottom: 8, display: 'flex' }}>
                <Input
                    placeholder={t('Name')}
                    value={schemeItem.name}
                    onChange={(e) => updateScheme(index, 'name', e.target.value)}
                    size="small"
                />
                <Select
                    value={schemeItem.chart}
                    onChange={(val) => updateScheme(index, 'chart', val)}
                    style={{ width: 120 }}
                    size="small"
                >
                    <Select.Option value={0}>{t('Primary')}</Select.Option>
                    <Select.Option value={1}>{t('Secondary')}</Select.Option>
                </Select>
                <Select
                    value={schemeItem.type}
                    onChange={(val) => updateScheme(index, 'type', val)}
                    style={{ width: 120 }}
                    size="small"
                >
                    <Select.Option value={0}>{t('Line')}</Select.Option>
                    <Select.Option value={1}>{t('Area')}</Select.Option>
                    <Select.Option value={2}>{t('Point')}</Select.Option>
                    <Select.Option value={3}>{t('Point Top')}</Select.Option>
                    <Select.Option value={4}>{t('Point Bottom')}</Select.Option>
                </Select>
                <Popover
                    content={
                        <SketchPicker
                            color={schemeItem.color}
                            onChangeComplete={(color) => updateScheme(index, 'color', color.hex)}
                        />
                    }
                    trigger="click"
                    visible={colorPickerVisible === index}
                    onVisibleChange={(visible) => setColorPickerVisible(visible ? index : null)}
                >
                    <Button
                        style={{
                            backgroundColor: schemeItem.color,
                            width: '24px',
                            height: '24px',
                            borderRadius: '4px',
                            marginTop: '5px',
                            border: '1px solid #d9d9d9',
                        }}
                        size="small"
                    />
                </Popover>
                <Button danger onClick={() => removeScheme(index)} icon={<DeleteOutlined />} size="small" />
            </Space>
        );
    }
);

export const SchemeEditor = ({ scheme = [], setScheme, code, params }) => {
    const { sendMessage, subscribeToObjects, connected } = useWebSocket();
    const { t } = useTranslation();
    const [colorPickerVisible, setColorPickerVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState([]);

    // Function to parse scheme
    const parseScheme = () => {
        if (!connected) return;

        setLoading(true);
        sendMessage({ object: 'indicators', method: 'scheme', data: { code: code, params: JSON.stringify(params) } });

        const unsubscribe = subscribeToObjects(['indicators'], (data) => {
            if (data.object === 'indicators' && data.method === 'scheme') {
                setLoading(false);
                if (data.data.error) {
                    notification.error({
                        message: t('Error'),
                        description: data.data.error,
                    });
                } else if (data.data.scheme) {
                    let parsedScheme = data.data.scheme;
                    if (typeof parsedScheme === 'string') {
                        try {
                            parsedScheme = JSON.parse(parsedScheme);
                        } catch (e) {
                            console.error('Error parsing received scheme:', e);
                            parsedScheme = [];
                        }
                    }
                    setScheme(parsedScheme);
                    notification.success({
                        message: t('Success'),
                        description: t('Scheme parsed successfully'),
                    });
                }
            }
        });

        // Clean up the subscription when the request is complete
        return () => {
            unsubscribe();
        };
    };

    // Fetch chart data when code or params change
    useEffect(() => {
        if (!connected) return;

        let isMounted = true;
        setLoading(true);

        sendMessage({
            object: 'indicators',
            method: 'sample',
            data: { code: code, params: JSON.stringify(params) },
        });

        const unsubscribe = subscribeToObjects(['indicators'], (data) => {
            if (data.object === 'indicators' && data.method === 'sample') {
                if (data.data?.error) {
                    if (isMounted) {
                        notification.error({
                            message: t('Error'),
                            description: data.data.error,
                        });
                        setLoading(false);
                    }
                    return;
                }
                if (data.data?.chart) {
                    try {
                        const parsedData = Array.isArray(data.data.chart)
                            ? data.data.chart
                            : JSON.parse(data.data.chart);
                        if (isMounted) {
                            setChartData(parsedData);
                            setLoading(false);
                        }
                    } catch (e) {
                        console.error('Error parsing chart data:', e);
                        if (isMounted) {
                            setLoading(false);
                        }
                    }
                } else {
                    if (isMounted) {
                        setLoading(false);
                    }
                }
            }
        });

        // Clean up the subscription when the component unmounts or dependencies change
        return () => {
            isMounted = false;
            unsubscribe();
        };
    }, [code, params, connected]);

    const updateScheme = (index, key, val) => {
        setScheme((prevScheme) => prevScheme.map((s, i) => (i === index ? { ...s, [key]: val } : s)));
    };

    const removeScheme = (index) => {
        setScheme((prevScheme) => prevScheme.filter((_, i) => i !== index));
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#0a0908',
                padding: '20px',
                boxSizing: 'border-box',
            }}
        >
            <div style={{ display: 'flex', height: '100%' }}>
                {/* Scheme Controls Section */}
                <div
                    style={{
                        width: '30%',
                        paddingRight: '10px',
                        overflowY: 'auto',
                        border: '1px solid #333', // Border for scheme controls
                        borderRadius: '8px',
                        padding: '10px',
                    }}
                >
                    <Space style={{ marginBottom: '12px' }}>
                        <Button
                            type="primary"
                            onClick={parseScheme}
                            size="small"
                            disabled={loading}
                        >
                            {loading ? <Spin size="small" /> : t('Parse Scheme')}
                        </Button>

                    </Space>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {scheme.map((s, index) => (
                            <SchemeItem
                                key={index}
                                schemeItem={s}
                                index={index}
                                updateScheme={updateScheme}
                                removeScheme={removeScheme}
                                colorPickerVisible={colorPickerVisible}
                                setColorPickerVisible={setColorPickerVisible}
                            />
                        ))}
                    </div>
                </div>

                {/* Charts Section */}
                <div
                    style={{
                        width: '70%',
                        position: 'relative',
                        backgroundColor: '#0a0908',
                        marginLeft: '10px',
                        border: '1px solid #333', // Border for charts
                        borderRadius: '8px',
                        padding: '10px',
                    }}
                >
                    {loading && (
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <Spin size="large" />
                        </div>
                    )}
                    <Charts chartData={chartData} scheme={scheme} style={{ backgroundColor: 'transparent' }} />
                </div>
            </div>
        </div>
    );
};
